import React, { useMemo } from "react"
import "./ProjectDetail.scss"
import Contact from "../../components/Contact/Contact"
import FeaturedProject from "../../components/FeaturedProject/FeaturedProject"
import ZigZag from "../../components/ZigZag/ZigZag"
import Share from "../../components/Share/Share"
import { Layout } from "../../layouts"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import { graphql } from "gatsby"
import PostBody from "../../components/PostBody/PostBody"
import SEO from "../../components/Seo"
import { localizeDataNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const ZIGZAG_IMAGE_SIDE_START = "left"

const ProjectDetailTemplate = ({
  pageContext: { projectPost, routePrefix, lang, companies },
  data,
  location,
}) => {
  const { allDzemoApiCompanies, allDzemoApiZigzags } = useMemo(
    () => localizeDataNodes(data, lang),
    [data, lang]
  )

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )
  const projectTitleCapitalized = capitalizeFirstLetter(projectPost[lang].name)


  return (
    <Layout
      location={location}
      lang={lang}
      pageData={projectPost}
      routePrefix={routePrefix}
      companies={companies}
    >
      <SEO
        title={projectTitleCapitalized}
        canonical={location.pathname}
        data={projectPost[lang]}
      />
      <div className="project-detail-page">
        <FeaturedProject data={projectPost[lang]} />

        <PostBody data={projectPost[lang]} className="project-post-body" />

        <Share data={allDzemoApiCompanies[0]} />

        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {projectPost[lang].zigzags.map((zigzag, index) => {
            const { id } = zigzag

            return (
              <ZigZag
                key={id}
                data={{
                  ...zigzag,
                  image: zigzag.image[0],
                  sideOfImage: zigzagImageSides[index],
                }}
              />
            )
          })}
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{ message: "Your message has been sent!", type: "error" }}
          />
        </section>
      </div>
    </Layout>
  )
}
export default ProjectDetailTemplate

export const query = graphql`
  query projectDetailQuery($projectId: Int!) {
    allDzemoApiCompanies(limit: 1) {
      nodes {
        en {
          facebook
          instagram
          twitter
        }
        de {
          facebook
          instagram
          twitter
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { projects: { elemMatch: { id: { eq: $projectId } } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }
  }
`
