import React from "react"
import "./FeaturedProject.scss"

const FeaturedProject = ({ data }) => {
  const { name, image } = data

  return (
    <div className="featured-project-container">
      <h1 className="card-title">{name}</h1>

      <picture>
        <source
          media="(max-width: 575px)"
          srcSet={`${image[0].staticImages.project_sm[0].src}`}
        />
        <source
          media="(min-width: 576px) and (max-width: 991px)"
          srcSet={`${image[0].staticImages.project_lg[0].src}`}
        />
        <source
          media="(min-width: 992px)"
          srcSet={`${image[0].staticImages.project_xl[0].src}`}
        />
        <img
          src={`${image[0].staticImages.project_xl[0].src}`}
          alt={image[0].alternativeText}
        />
      </picture>
    </div>
  )
}

export default FeaturedProject
